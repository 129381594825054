<template>
  <div  :class="{positionFixed: showLoginScreen}">
  <Nav :hideLoginBtn="true"/>
  <div class="cover">  
  <SignIn :state="true" :fullScreen="true"/>
  </div>
  </div>
</template>


<script>
import Nav from '../components/Nav/Nav.vue'

import SignIn from '../components/SignIn/SignIn.vue'
export default {
  components: {
    Nav,
    SignIn
  },
    data() {
    return {
      showLoginScreen: true,  
      }
    },
    methods: {
      toggleSignIn(){
        this.showLoginScreen = !this.showLoginScreen
      }
    },
}
</script> 

<style scoped>
.positionFixed {
  position: fixed;
}

.cover {
  background: linear-gradient(rgba(50, 114, 188, 0.5), rgba(50, 114, 188, 0.5)), url('https://images.unsplash.com/photo-1644088379091-d574269d422f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2186&q=80');
  background-size: cover;
}
</style>