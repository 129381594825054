<template>
<div v-if="state" class="flex flex-col h-screen z-100 inset-0 overflow-y-auto overscroll-contain bg-gray-200">
<!-- @click="emitCloseSignIn" -->
<!-- <button  type="button" class="absolute right-0 w-20 h-20 hover:text-sky-800 flex flex-col justify-center">
              <svg class="self-center h-6 w-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
  </button> -->
  <div class="grid place-items-center my-20 sm:my-auto h-screen" :class="{screenFull: fullScreen}">
    <div class="w-11/12 p-12 sm:w-10/12 md:w-8/12 lg:w-6/12 
            px-6 py-10 sm:px-10 sm:py-6 
            bg-white  shadow-md lg:shadow-lg
            whiteBorder">
      <form class="mt-10" method="POST">
        <label for="clientId" class="block text-md font-semibold text-mainWhite uppercase">Client Number </label>
        <span v-if="clientId.length > 0" class="mt-8 pl-4 pr-4 pt-2 text-mainWhite">Your Client Number will be saved in a cookies</span>
        <input id="clientId" v-model="clientId" name="clientId" placeholder="Client Number" 
                    class="block w-full py-3 px-3 mt-2 
                    text-gray-800 appearance-none 
                    focus:text-gray-500 focus:outline-none focus:border-gray-200"
                    required />
        <label for="password" class="block mt-4 text-md font-semibold text-mainWhite uppercase">Password</label>
        <input id="password" type="password" name="password" placeholder="Password" autocomplete="current-password"
                    class="block w-full py-3 px-3 mt-2 mb-4
                    text-gray-800 appearance-none 
                    focus:text-gray-500 focus:outline-none focus:border-gray-200"
                    required />
                    <div class="flex justify-between">
                    <button type="submit" class="mt-8 font-semibold px-8 py-2 buttons hover:text-mainSecondary text-base">Login</button>
                    <div class="mt-8 pl-4 pr-4 pt-2 text-mainWhite text-xs">By clicking Login, you agree to our 
                      <a href="terms-and-conditions" target="_blank" class="cursor-pointer text-mainSecondary text-xs">T&amp;C.</a>
                      To know what personal data we collect and how we use it see our 
                      <a href="privacy-policy" target="_blank" class="cursor:pointer text-mainSecondary text-xs">Privacy Policy</a></div>
                    </div>
                    
            </form>
        </div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    state: Boolean,
    fullScreen: Boolean,
  },
  data() {
    return {
      clientId: '',
      password: ''
    }
  },
  methods: {
    emitCloseSignIn() {
      this.$emit('close-sign-in')
    }
  }
}
</script>

<style scoped>
.whiteBorder {
  border: 1px solid white;
  background-color: rgb(50, 114, 188);
}

.buttons {
  border: 2px solid white;
  color: white;
}

.buttons:hover {
  color: #00bfcd;
  border: 2px solid #00bfcd;
}

.screenFull {
  width: 100vw;
}
</style>