<template>

<div v-if="!show && !state" class="flex flex-wrap place-items-center fixed w-full z-10 top-0 bg-mainPrimary ">
  <section class="relative mx-auto">
      <!-- navbar -->
    <nav class="flex justify-between w-screen">
      <div class="px-5 xl:px-12 py-6 flex w-full items-center">
        <a class="text-1xl font-bold font-heading" href="/">
          <img class="h-12" src="./HorizontalLogoWhite.svg" alt="logo">
        </a>
        <!-- Nav Links -->
        <ul class="hidden md:flex px-4 mx-auto font-heading space-x-12 ">
          <!-- <li><a class="navColors" href="/client">Client Zone</a></li>
          <li><a class="navColors" href="/adviser">Adviser</a></li>
          <li><a class="navColors" href="/documents">Document Library</a></li> -->
        </ul>
        <!-- Header Icons -->
        <div class="hidden md:flex items-center space-x-5 items-center">      
          <div class="flex flex-col">
          <a href="mailto::info@wmanalyticsou.com">
            <p class="text-white navColors text-sm">info@wmanalyticsou.com</p>
          </a>
          </div>
          <!-- @click="emitShowSignin" add later to login  -->
          <a href="/login" :class="{displayNone: hideLoginBtn}">
					<button class="flex items-center px-4 py-3 text-white text-2xl font-semibold navColors">Login</button>
          </a>
        </div>
      </div>
      <div class="navbar-burger self-center relative mr-3 md:hidden">
        <nav>
            <button class="w-10 h-10 focus:outline-none bg-white" @click="toggleMobileNav">
                <span class="sr-only">Open main menu</span>
                <div class=" block w-5 mr-5 lg:hidden left-1/2 top-1/2 pl-3 transform  -translate-x-1/2 -translate-y-1/2">
                    <span aria-hidden="true" class="block absolute h-0.5 w-5 bg-mainWhite transform transition duration-150 ease-in-out" :class="{'rotate-45': mobileNavOpen,' -translate-y-1.5': !mobileNavOpen }"></span>
                    <span aria-hidden="true" class="block absolute  h-0.5 w-5 bg-mainWhite   transform transition duration-150 ease-in-out" :class="{'opacity-0': mobileNavOpen }"></span>
                    <span aria-hidden="true" class="block absolute  h-0.5 w-5 bg-mainWhite transform  transition duration-150 ease-in-out" :class="{'-rotate-45': mobileNavOpen, ' translate-y-1.5': !mobileNavOpen}"></span>
                </div>
            </button>
        </nav>
      <div>
	</div>
	</div>
</nav>
  </section>
</div>
        <!-- Mobile Nav -->

<div v-if="state" :class="{positionFixed: state}" class="flex flex-col h-screen z-100 inset-0 overflow-y-auto overscroll-contain bg-gray-200">
<!-- @click="toggleLoginScreenMobile" -->
<button  type="button" class="absolute right-0 w-20 h-20 flex flex-col justify-center">
              <svg class="self-center h-6 w-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
  </button>
  <div class="grid place-items-center mx-2 my-20 sm:my-auto">
    <div class="w-11/12 p-12 sm:w-8/12 md:w-6/12 lg:w-5/12 2xl:w-4/12 
            px-6 py-10 sm:px-10 sm:py-6 
            bg-white rounded-lg shadow-md lg:shadow-lg">
      <form class="mt-10" method="POST">
        <label for="email" class="block text-xs font-semibold uppercase">E-mail</label>
        <input id="email" type="email" name="email" placeholder="e-mail address" autocomplete="email"
                    class="block w-full py-3 px-1 mt-2 
                    appearance-none 
                    border-b-2 border-gray-100
                    focus:outline-none focus:border-gray-200"
                    required />
        <label for="password" class="block mt-2 text-xs font-semibold uppercase">Password</label>
        <input id="password" type="password" name="password" placeholder="password" autocomplete="current-password"
                    class="block w-full py-3 px-1 mt-2 mb-4
                    appearance-none 
                    border-b-2 border-gray-100
                    focus:outline-none focus:border-gray-200"
                    required />
          <button type="submit"
                    class="w-full py-3 mt-10 rounded-sm
                    font-medium uppercase
                    focus:outline-none hover:bg-gray-500 focus:bg-gray-500 hover:shadow-none">
                    Login</button>
                    <!-- @click="toggleLoginScreenMobile" add to cancel -->
                    <button type="submit" 
                    class="w-full py-3 mt-5 rounded-sm
                    font-medium uppercase
                    focus:outline-none hover:bg-gray-500 focus:bg-gray-500 hover:shadow-none">
                    Cancel</button>
                
            </form>
        </div>
    </div>
</div>


</template>

<script>


export default {

  props: {
    show: Boolean,
    hideLoginBtn: Boolean,
  },
  data(){ 
    return {
      mobileNavOpen: false,
      state: false,
      companyName: 'Ifs Financial Management',
      mobileNavigation: [ // decide how to render these, add some kind of transition?
        { name: 'Dashboard', href: '#', current: true },
        { name: 'Team', href: '#', current: false },
        { name: 'Projects', href: '#', current: false },
        { name: 'Calendar', href: '#', current: false },
      ] 
    }
  },
  methods: {
    toggleMobileNav(){
      this.mobileNavOpen = !this.mobileNavOpen
      this.$emit('show-login')
    },
    emitShowSignin(){
      this.$emit('toggle-sign-in')
    },
    toggleLoginScreenMobile() {
      this.state = !this.state
      console.log(this.state)
    }
  }
}
</script>

<style scoped>
.positionFixed {
  position: fixed;
  justify-content: center;
  width: 100%;
}

.navColors {
  color:white
}
.navColors:hover {
  color: #00bfcd
}

.navColors:active {
  color: #00bfcd;
}
.navColors:focus {
  color: #00bfcd;
}

.displayNone {
  display: none;
}
</style>