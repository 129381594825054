<template>
  <footer class="bg-mainPrimary px-5 pt-8 z-100 horizontal-line position-bot">
  <div class="container w-full max-w-6xl mx-auto">
    <div class="alignment text-left px-5">
      <div>
      <img class="h-9" src="./HorizontalLogoWhite.svg" alt="logo">
      </div>
      <div class="pt-5 md:pt-0 ">
        <div class="flex flex-col items-top mb-6">
          <div class="sm-w-full ml-auto">
            <p class="text-mainWhite text-sm">WM Analytics OU</p>
            <p class="text-mainWhite text-sm">Harju maakond, Kuusalu vald,</p>
            <p class="text-mainWhite text-sm"> Pudisoo küla, Männimäe/1, 74626, Estonia</p>
          </div>
          <!-- <div class="sm-w-full md:w-5/12">
              <a href="tel:0330 088 4221">
            <p class="white text-lg">tel: 0330 088 4221</p>
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</footer>
</template>

<script>


export default {

    computed: {
      currentDate() {
        return new Date().getFullYear();
      }
    }
}
</script>

<style scoped>

.alignment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.position-bot {
  bottom: 0;
  right: 0;
  left: 0;
}
.horizontal-line {
  border-top: 1px solid white;
}
.white {
  color: white;
}

.white:hover {
  cursor: pointer;
  color: #00bfcd;
}


@media (min-height: 1000px) {
  .position-bot{
    position: absolute;
  }
}
  


@media (min-width: 768px) {

  .alignment {
    flex-direction: row;
    justify-content: space-between;
  }
  .mail {
    border-right: 1px solid white;


  }
}
</style>