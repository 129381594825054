<template>
<div class="w-full mt-20 text-mainWhite hero" >
    <div class="flex justify-center bg-white hero-height">
        <div class="flex items-center text-center lg:text-left px-8 md:px-12">
            <div class="content">
                <h2 class="text-3xl font-semibold md:text-4xl ">{{heroText.title}}</h2>
                <hr/>
                <p v-for="papragraph in heroText.paragraphs" :key="papragraph.id" class="mt-2 text-xl font-bold">{{papragraph.text}}</p>
                <a href="/login">
                <button class="mt-8 bg-white font-semibold px-8 py-2 opacity-100 border-2 border-black hover:text-mainSecondary text-base">Login</button>
              </a>
            </div>
        </div>
    </div>
</div>
</template>:
<script>
export default {
props: {
  heroText: Object,
  },
}

</script>

<style scoped>
.hero {

  background: linear-gradient(rgba(50, 114, 188, 0.5), rgba(50, 114, 188, 0.5)), url('https://images.unsplash.com/photo-1644088379091-d574269d422f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2186&q=80');
  background-size: cover;
}

.hero-height {
  height: 55vh;
}


@media (max-height: 500px) {
  .hero {
    margin-top: 6rem;
  }
}
</style>