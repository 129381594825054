<template>
<div>

 <Nav :hideLoginBtn="false" @show-login="toggleSignIn" />
 <div v-if="!showLoginScreen">
  <HeroSection :heroText="heroSlideText" />
  <Accordion :accordions="accordions" />
 </div>

  <SignIn v-else :state="true"/>
  <Footer />
 </div>
</template>

<script>
import Accordion from '../../components/Accordion/Accordion.vue'
import Nav from '../../components/Nav/Nav.vue'
import SignIn from '../../components/SignIn/SignIn.vue'
import HeroSection from '../../components/HeroSection/HeroSection.vue'
// import ImageSection from '../../components/ImageSection/ImageSection.vue'
import Footer from '../../components/footer/Footer.vue'


export default {
  components: {
    Accordion,
    Nav,
    SignIn,
    HeroSection,
    // ImageSection,
    Footer,
  },
  data() {
    return {
      showLoginScreen: false,
      accordions: [
      {
        id: 1,
        title: 'Performance Measurement',
        paragraphs: 
        [
          {text: `View your Portfolio Performance`},
          {text: `Compare Individual Funds`},
          {text: `Check Performance against your Benchmarks`},
        ],
      },
      {
        id: 2,
        title: `Costs Analysis`,
        paragraphs: [
          {text: `View Portfolio Running Costs and Fund Information Documents`},
        ],
      },
      { 
        id: 3,
        title: 'Risk Profiling',
        paragraphs: [
          {text: 'Compare Fund and Portfolio Risk Profiles'},
        ],
      },
       { 
        id: 4,
        title: 'Investment Insights',
        paragraphs: [
          {text: 'Easy to Understand Analysis and News'},
        ],
      },
      ],
        heroSlideText: {
        title: 'WM Analytics',
        paragraphs: [
          {
            id: 'par1',
            text: `Investment Analysis for Clients & Advisers`
          },
        ]
      },
    }
  },

  methods: {
      toggleSignIn(){
        this.showLoginScreen = !this.showLoginScreen
      }
    },
}
</script>

<style scoped>
.positionFixed {
  position: fixed;
}
</style>