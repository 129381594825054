<template>

<div class="min-w-screen bg-mainPrimary flex items-center justify-center ">
    <div class="w-full bg-mainPrimary px-5 py-8  md:py16  text-white font-light">
        <div class="w-full max-w-6xl mx-auto pb-5">
                <section >
                  <AccordionColumn 
                  v-for="(accordionData, index) in accordions" 
                  :key="index" 
                  :id="accordionData.id"
                  :title="accordionData.title"
                  :paragraphs="accordionData.paragraphs"
                   />
            </section>
        </div>
    </div>
</div>
              
</template>

<script>
import AccordionColumn from './AccordionColumn.vue'
export default {
  components: {
    AccordionColumn,
  },
  props: {
    accordions: Array,
  }
}

</script>