<template>
  <router-view />
</template>


<script>


</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(50, 114, 188);
  }

  @media (min-height: 1000px) {
    #app {
      height: 90vh;
    }
  }
</style>